import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import UserManagement from '../views/UserManagement.vue'
import RegistrationStatus from '../views/RegistrationStatus.vue'
import Detail from '../views/Detail.vue'
import Profile from '../views/Profile.vue'
import Version from '../views/Version.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: UserManagement
  },
  {
    path: '/registration-status/:product/:status',
    name: 'RegistrationStatus',
    component: RegistrationStatus
  },
  {
    path: '/detail/:product/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/version/:product',
    name: 'Version',
    component: Version
  },
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
