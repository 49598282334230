<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">{{titlePage}}</h1>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div class="row" style="padding-bottom:20px;">
                            <div class="col-6">
                                <!-- <button class="d-sm-inline-block btn btn-sm btn-success shadow-sm"><i
                                    class="fas fa-file-csv fa-sm text-white-50"></i> Export to CSV</button> -->
                                <!-- <vue-pikaday placeholder="Choose a date..." v-model="date" class="form-control"/> -->
                                <!-- <input v-model="formatDate" type="text" class="form-control bg-light border-0 small"> -->
                            </div>
                            <div class="col-6">
                                <input v-model="filterTable" type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search">
                            </div>
                        </div>
                        <div class="row">
                            <b-table
                                bordered
                                striped
                                hover
                                show-empty
                                empty-text="There are no records to show"
                                index :items="allProductStatuses"
                                :fields="fields"
                                :filter="filterTable"
                                :per-page="perPage"
                                :current-page="currentPage"
                                responsive
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #cell(action)="data">
                                    <button @click="getDetail(data.item._id)" class="btn btn-warning btn-circle btn-sm">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                >
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright 2021 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
// import '@netcz/vue-pikaday'
// import moment from 'moment'

export default {
  name: 'RegistrationRequest',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url', 'urldatalog', 'urlelto', 'urlpathgenimg', 'urlauge', 'urlqcm'],
  data: () => {
      return {
        //   date: null,
          userInfo: {},
          urlGet: "",
          titlePage: "",
          allProductStatuses: [],
          filterTable: "",
          perPage: 10,
          currentPage: 1,
          fields: [
                {
                    key: 'index',
                    label: 'No'
                },
                {
                    key: 'idlaptop',
                    label: 'Machine Id',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'company',
                    label: 'Company',
                    sortable: true
                },
                {
                    key: 'address',
                    label: 'address',
                    sortable: true
                },
                {
                    key: 'phone',
                    label: 'Phone Number',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'email',
                    sortable: true
                },
                // {
                //     key: 'submitreport',
                //     label: 'Submit Report'
                // },
                {
                    key: 'action',
                    label: 'Detail'
                },
          ]
      }
  },
  methods : {
      getUserInfo() {
        let config = {
            method: 'POST',
            headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
            baseURL: `${this.url}/user/check`,
        }
        axios(config)
        .then((response) => {
            let userInfo = response.data.data
            if (userInfo.role == 'admin' || userInfo.role == 'superadmin') {
                this.userInfo = userInfo
            }
            else {
                this.$router.push('/dashboard')
                alert('You are not authorized to access this page')
            }
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getAllProductStatuses() {
        let config = {
            method: 'GET',
            headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
            baseURL: this.urlGet,
        }

        axios(config)
        .then((response) => {
            this.allProductStatuses = response.data.data
        })
      },
      getDetail(id) {
          let routeData = this.$router.resolve(`/detail/${this.$route.params.product}/${id}`);
          window.open(routeData.href, '_blank');
      },
      setRegistrationStatusPage() {
          let product = this.$route.params.product
          let status = this.$route.params.status

          if (product == "datalog") {
              this.urlGet = `${this.urldatalog}/datalog/alldata/${status}`
          }
          else if(product == "elto") {
              this.urlGet = `${this.urlelto}/elto/alldata/${status}`
          }
          else if(product == "pathgenimg") {
              this.urlGet = `${this.urlpathgenimg}/pathgenimg/alldata/${status}`
              product = "digipath"
          }
          else if(product == "auge") {
              this.urlGet = `${this.urlauge}/auge/alldata/${status}`
              console.log(this.urlGet)
          }
          else if(product == "qcm") {
              this.urlGet = `${this.urlqcm}/qcm/alldata/${status}`
              console.log(this.urlGet)
          }
          else {
            this.$router.push('/404')
          }

          this.titlePage = `${product.charAt(0).toUpperCase() + product.slice(1)} Registration ${status.charAt(0).toUpperCase() + status.slice(1)}`
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.setRegistrationStatusPage()
        this.getUserInfo()
        this.getAllProductStatuses()
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
  },
  computed: {
        rows() {
            return this.allProductStatuses.length
        }
    }
}
</script>
