<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">User Management</h1>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div class="row" style="padding-bottom:20px;">
                            <div class="col-6">
                                <!-- <button class="d-sm-inline-block btn btn-sm btn-success shadow-sm"><i
                                    class="fas fa-file-csv fa-sm text-white-50"></i> Export to CSV</button> -->
                                <!-- <vue-pikaday placeholder="Choose a date..." v-model="date" class="form-control"/> -->
                                <!-- <input v-model="formatDate" type="text" class="form-control bg-light border-0 small"> -->
                                <button
                                    class="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                    data-toggle="modal"
                                    data-target="#addNewUser"
                                    >
                                    <i class="fas fa-file-csv fa-sm text-white-50"></i>
                                    Add New User
                                </button>
                            </div>
                            <div class="col-6">
                                <input v-model="filterTable" type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search">
                            </div>
                        </div>
                        <div class="row">
                            <b-table
                                bordered
                                striped
                                hover
                                show-empty
                                empty-text="There are no records to show"
                                index :items="allUsers"
                                :fields="fields"
                                :filter="filterTable"
                                :per-page="perPage"
                                :current-page="currentPage"
                                responsive
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #cell(edit)="data">
                                    <button @click="editUser(data.item._id)" class="btn btn-warning btn-circle btn-sm">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </template>
                                <template #cell(delete)="data">
                                    <button @click="deleteUser(data.item._id, data.item.username)" class="btn btn-danger btn-circle btn-sm">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                >
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="addNewUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New User</h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputName">Name</label>
                                    <input type="text" v-model="name" class="form-control" id="exampleInputName" aria-describedby="nameHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputUsername">Username</label>
                                    <input type="text" v-model="username" class="form-control" id="exampleInputUsername" aria-describedby="usernameHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail">Email</label>
                                    <input type="text" v-model="email" class="form-control" id="exampleInputEmail" aria-describedby="emailHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword">Password</label>
                                    <input type="password" v-model="password" class="form-control" id="exampleInputPassword" aria-describedby="passwordHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputRePassword">Retype Password</label>
                                    <input type="password" v-model="repassword" class="form-control" id="exampleInputRePassword" aria-describedby="rePasswordHelp">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputRole">Role</label>
                                    <select v-model="role" class="form-control" id="exampleInputRole" aria-describedby="roleHelp">
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                        <option value="superadmin">Superadmin</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                            <button @click="addNewUser()" class="btn btn-primary" type="button" data-dismiss="modal">Add New User</button>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright 2021 Genose C19. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
// import '@netcz/vue-pikaday'
// import moment from 'moment'

export default {
  name: 'UserManagement',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url'],
  data: () => {
      return {
        //   date: null,
          userInfo: {},
          allUsers: [],
          name: "",
          username: "",
          email: "",
          password: "",
          repassword: "",
          role: "",
          filterTable: "",
          perPage: 10,
          currentPage: 1,
          fields: [
                {
                    key: 'index',
                    label: 'No'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'username',
                    label: 'Username',
                    sortable: true
                },
                {
                    key: 'role',
                    label: 'Role',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'email',
                    sortable: true
                },
                {
                    key: 'edit',
                    label: 'Edit'
                },
                {
                    key: 'delete',
                    label: 'Delete'
                },
          ]
      }
  },
  methods : {
      getUserInfo() {
        let config = {
            method: 'POST',
            headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
            baseURL: `${this.url}/user/check`,
        }
        axios(config)
        .then((response) => {
            let userInfo = response.data.data
            if (userInfo.role == 'superadmin') {
                this.userInfo = userInfo
            }
            else {
                this.$router.push('/dashboard')
                alert('You are not authorized to access this page')
            }
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getAllUsers() {
        axios.get(`${this.url}/user/all`)
        .then((response) => {
            this.allUsers = response.data.data
        })
      },
      editUser(id) {
          let routeData = this.$router.resolve(`/edit-user/${id}`);
          window.open(routeData.href, '_blank');
      },
      addNewUser() {
          let obj = {
              name: this.name,
              username: this.username,
              email: this.email,
              role: this.role,
          }
          if (this.password == this.repassword) {
                obj.password = this.password
                axios.post(`${this.url}/user/signup`, obj)
                    .then(() => {
                        this.getAllUsers()
                        Swal.fire('Added New User Successfully', '', 'success')

                    })
                    .catch((error) => {
                        Swal.fire('Something went wrong!', '', 'error')
                        console.log(error)
                    })
          }
          else {
              Swal.fire('Password and Retype Password does not match!', '', 'error')
          }

      },
      deleteUser(id, username) {
          Swal.fire({
                title: `Do you want to delete user ${username}`,
                showCancelButton: true,
                confirmButtonText: `Yes`,
          }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${this.url}/user/id/${id}`)
                          .then(() => {
                              this.getAllUsers()
                              Swal.fire('User Deleted!', '', 'success')
                          })
                          .catch((error) => {
                              Swal.fire('Something went wrong!', '', 'error')
                              console.log(error)
                          })
                }
          })
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.getUserInfo()
        this.getAllUsers()
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
       }
  },
  computed: {
        rows() {
            return this.allUsers.length
        }
    }
}
</script>
