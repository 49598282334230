<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center">
            <div class="sidebar-brand-icon">
                <img class="sidebar-brand-icon" src="/img/Logo-Nanosense-Crop-White.png" width="70">
            </div>
            <div class="sidebar-brand-text mx-3">Nanosense Dashboard</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></router-link>
        </li>

        <hr v-if="role == `superadmin`" class="sidebar-divider my-0">

        <!-- Nav Item - User Management -->
        <li v-if="role == `superadmin`" class="nav-item">
            <router-link to="/user-management" class="nav-link">
                <i class="fas fa-fw fa-users"></i>
                <span>User Management</span></router-link>
        </li>

        <hr v-if="role == `admin` || role == `superadmin`" class="sidebar-divider my-0">

        <br>
        <div v-if="role == `admin` || role == `superadmin`" class="sidebar-heading">
            Registration
        </div>

        <li v-if="role == `admin` || role == `superadmin`" class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDatalog"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-database"></i>
                <span>Genose Data Logger</span>
            </a>
            <div id="collapseDatalog" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Registration Status:</h6>
                    <a class="collapse-item" href="/registration-status/datalog/request">Request</a>
                    <a class="collapse-item" href="/registration-status/datalog/registered">Registered</a>
                    <a class="collapse-item" href="/registration-status/datalog/rejected">Rejected</a>
                    <a class="collapse-item" href="/registration-status/datalog/disabled">Disabled</a>
                </div>
            </div>
        </li>

        <li v-if="role == `admin` || role == `superadmin`" class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseElto"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-cog"></i>
                <span>Elto</span>
            </a>
            <div id="collapseElto" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Registration Status:</h6>
                    <a class="collapse-item" href="/registration-status/elto/request">Request</a>
                    <a class="collapse-item" href="/registration-status/elto/registered">Registered</a>
                    <a class="collapse-item" href="/registration-status/elto/rejected">Rejected</a>
                    <a class="collapse-item" href="/registration-status/elto/disabled">Disabled</a>
                    <h6 class="collapse-header">Version:</h6>
                    <a class="collapse-item" href="/version/elto">Update Version</a>
                </div>
            </div>
        </li>

        <li v-if="role == `admin` || role == `superadmin`" class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAuge"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-eye"></i>
                <span>Auge</span>
            </a>
            <div id="collapseAuge" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Registration Status:</h6>
                    <a class="collapse-item" href="/registration-status/auge/request">Request</a>
                    <a class="collapse-item" href="/registration-status/auge/registered">Registered</a>
                    <a class="collapse-item" href="/registration-status/auge/rejected">Rejected</a>
                    <a class="collapse-item" href="/registration-status/auge/disabled">Disabled</a>
                    <h6 class="collapse-header">Version:</h6>
                    <a class="collapse-item" href="/version/auge">Update Version</a>
                </div>
            </div>
        </li>

        <li v-if="role == `admin` || role == `superadmin`" class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePathgenimg"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-microscope"></i>
                <span>DigiPath</span>
            </a>
            <div id="collapsePathgenimg" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Registration Status:</h6>
                    <a class="collapse-item" href="/registration-status/pathgenimg/request">Request</a>
                    <a class="collapse-item" href="/registration-status/pathgenimg/registered">Registered</a>
                    <a class="collapse-item" href="/registration-status/pathgenimg/rejected">Rejected</a>
                    <a class="collapse-item" href="/registration-status/pathgenimg/disabled">Disabled</a>
                    <h6 class="collapse-header">Version:</h6>
                    <a class="collapse-item" href="/version/pathgenimg">Update Version</a>
                </div>
            </div>
        </li>

        <li v-if="role == `admin` || role == `superadmin`" class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseQcm"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-compact-disc"></i>
                <span>QCM</span>
            </a>
            <div id="collapseQcm" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Registration Status:</h6>
                    <a class="collapse-item" href="/registration-status/qcm/request">Request</a>
                    <a class="collapse-item" href="/registration-status/qcm/registered">Registered</a>
                    <a class="collapse-item" href="/registration-status/qcm/rejected">Rejected</a>
                    <a class="collapse-item" href="/registration-status/qcm/disabled">Disabled</a>
                    <!-- <h6 class="collapse-header">Version:</h6>
                    <a class="collapse-item" href="/version/qcm">Update Version</a> -->
                </div>
            </div>
        </li>

        <hr v-if="role == `superadmin`" class="sidebar-divider my-0">

        <!-- Heading -->
        <!-- <div class="sidebar-heading">
            Download
        </div> -->

        <!-- <hr class="sidebar-divider my-0"> -->

        <!-- <li class="nav-item">
            <router-link to="/token-generator" class="nav-link">
                <i class="fas fa-fw fas fa-key"></i>
                <span>Token Generator</span></router-link>
        </li> -->

        <!-- Nav Item - Pages Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-cog"></i>
                <span>Components</span>
            </a>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Custom Components:</h6>
                    <a class="collapse-item" href="buttons.html">Buttons</a>
                    <a class="collapse-item" href="cards.html">Cards</a>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Utilities Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                aria-expanded="true" aria-controls="collapseUtilities">
                <i class="fas fa-fw fa-wrench"></i>
                <span>Utilities</span>
            </a>
            <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
                data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Custom Utilities:</h6>
                    <a class="collapse-item" href="utilities-color.html">Colors</a>
                    <a class="collapse-item" href="utilities-border.html">Borders</a>
                    <a class="collapse-item" href="utilities-animation.html">Animations</a>
                    <a class="collapse-item" href="utilities-other.html">Other</a>
                </div>
            </div>
        </li> -->

        <!-- Divider -->
        <!-- <hr class="sidebar-divider"> -->

        <!-- Heading -->
        <!-- <div class="sidebar-heading">
            Addons
        </div> -->

        <!-- Nav Item - Pages Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                aria-expanded="true" aria-controls="collapsePages">
                <i class="fas fa-fw fa-folder"></i>
                <span>Pages</span>
            </a>
            <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Login Screens:</h6>
                    <a class="collapse-item" href="login.html">Login</a>
                    <a class="collapse-item" href="register.html">Register</a>
                    <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
                    <div class="collapse-divider"></div>
                    <h6 class="collapse-header">Other Pages:</h6>
                    <a class="collapse-item" href="404.html">404 Page</a>
                    <a class="collapse-item" href="blank.html">Blank Page</a>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Charts -->
        <!-- <li class="nav-item">
            <a class="nav-link" href="charts.html">
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Charts</span></a>
        </li> -->

        <!-- Nav Item - Tables -->
        <!-- <li class="nav-item">
            <a class="nav-link" href="tables.html">
                <i class="fas fa-fw fa-table"></i>
                <span>Tables</span></a>
        </li> -->

        <!-- Divider -->
        <!-- <hr class="sidebar-divider d-none d-md-block"> -->

        <!-- Sidebar Toggler (Sidebar) -->
        <br>
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
    </ul>
</template>

<script>
export default {
  name: 'Sidebar',
  props: ['role']
}
</script>
