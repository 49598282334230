<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Change or Update {{product}} Version</h1>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div style="text-align:center;">
                            <h4>Current Version</h4>
                        </div>
                        <br>
                        <div>
                            <h5>Version:</h5>
                            <ul>
                                <li>{{currentversion}}</li>
                            </ul>
                            <br>
                            <h5>Release Notes:</h5>
                            <ul>
                                <li v-for="item in currentreleaseNotes" :key="item">{{item}}</li>
                            </ul>
                            <br>
                            <h5>Update date:</h5>
                            <p>{{currentDate}}</p>
                            <br>
                            <a :href="currentdownloadLink" target="_blank" rel="noopener noreferrer"><h5>Link Download...</h5></a>
                            <br>
                            <h5>Size information</h5>
                            <p>{{currentSize}}</p>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div style="text-align:center;">
                            <h4>Change Update Notification</h4>
                        </div>
                        <br>
                        <div v-if="!viewEdit">
                            <input v-model="password" type="password" class="form-control bg-light border-1 small" placeholder="Password...">
                            <br>
                            <div class="row" style="padding-bottom:50px; display: flex; justify-content: center;">
                                <button @click="changeViewEdit()" class="btn btn-primary" type="button" data-dismiss="modal">Unlock Edit</button>
                            </div>
                        </div>
                        <div v-if="viewEdit">
                            <div>
                                <input v-model="version" type="text" class="form-control bg-light border-1 small" placeholder="Version...">
                                <br>
                                <textarea v-model="releaseNotes" rows="4" type="text" class="form-control bg-light border-1 small" placeholder="Release Notes...">
                                </textarea>
                                <br>
                                <input v-model="downloadLink" type="text" class="form-control bg-light border-1 small" placeholder="Link Download...">
                                <br>
                                <input v-model="size" type="text" class="form-control bg-light border-1 small" placeholder="Size...">
                                <br>
                                <p>Note: Untuk Size tolong ditulis lengkap (ex: 65MB), Untuk Release Notes pisahkan dengan enter untuk tiap point, tetapi jangan tinggalkan empty line</p>
                            </div>
                            <br>
                            <div class="row" style="padding-bottom:50px; display: flex; justify-content: center;">
                                <button @click="editUpdateNotification()" class="btn btn-primary" type="button" data-dismiss="modal">Edit Update Notification</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright 2021 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'


export default {
  name: 'Version',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url', 'urlelto', 'urldatalog', 'urlpathgenimg', 'urlauge', 'urlqcm'],
  data: () => {
      return {
          userInfo: {},
          product: "",
          urlGet: "",
          version: "",
          releaseNotes: "",
          currentversion: "",
          currentreleaseNotes: [],
          currentdownloadLink: "",
          currentSize:"",
          currentDate: "",
          downloadLink: "",
          size: "",
          viewEdit: false,
          password: ""
      }
  },
  methods : {
      getUserInfo() {
        let config = {
            method: 'POST',
            headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
            baseURL: `${this.url}/user/check`,
        }
        axios(config)
        .then((response) => {
            let userInfo = response.data.data
            if (userInfo.role == 'superadmin') {
                this.userInfo = userInfo
            }
            else {
                this.$router.push('/dashboard')
                alert('You are not authorized to access this page')
            }
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getUpdateNotification() {

          let config = {
            method: 'POST',
            headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
            baseURL: `${this.urlGet}/version/current`,
          }

          axios(config)
                .then((response) => {
                    this.currentversion = response.data.version
                    this.currentreleaseNotes = response.data.whatsnew
                    this.currentdownloadLink  = response.data.download
                    this.currentSize = response.data.size
                    this.currentDate = new Date(response.data.date).toLocaleDateString("id-ID", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'})
                })
                .catch((error) => {
                    console.log(error)
                })
      },
      editUpdateNotification() {
          let updateReleaseNotes = this.releaseNotes.split(/\n/)

          let obj = {
              version: this.version,
              whatsnew: updateReleaseNotes,
              download: this.downloadLink,
              size: this.size,
              date: Date.now()
          }

          Swal.fire({
                title: `Do you want to change Update Notification`,
                showCancelButton: true,
                confirmButtonText: `Yes`,
          }).then((result) => {
                if (result.isConfirmed) {
                     let config = {
                        method: 'PATCH',
                        headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
                        baseURL: `${this.urlGet}/version/edit`,
                        data: obj
                    }
                    axios(config)
                          .then((response) => {
                              console.log(response.data)
                              this.currentversion = response.data.version
                              this.currentreleaseNotes = response.data.whatsnew
                              this.currentdownloadLink = response.data.download,
                              this.currentSize = response.data.size
                              this.currentDate = new Date(response.data.date).toLocaleDateString("id-ID", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'})
                              Swal.fire('Update Notification Changed!', '', 'success')
                          })
                          .catch((error) => {
                              Swal.fire('Something went wrong!', '', 'error')
                              console.log(error)
                          })
                }
          })
      },

      setVersionPage() {
            let product = this.$route.params.product
            this.product = product.charAt(0).toUpperCase() + product.slice(1)

            if (product == "datalog") {
                this.urlGet = this.urldatalog
            }
            else if(product == "elto") {
                this.urlGet = this.urlelto
            }
            else if(product == "pathgenimg") {
                this.urlGet = this.urlpathgenimg
                this.product = "Digipath"
            }
            else if(product == "auge") {
                this.urlGet = this.urlauge
            }
            else if(product == "qcm") {
                this.urlGet = this.urlqcm
            }
            else {
                this.$router.push('/404')
            }
      },

      changeViewEdit() {
          if (this.password == "timsoftwareganteng") {
              this.viewEdit = true
          }
          else {
              alert("Wrong Password")
          }
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.getUserInfo()
        this.setVersionPage()
        this.getUpdateNotification()
      }
  }
}
</script>
