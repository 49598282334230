<template>
  <div class="home bg-gradient-primary">
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center align-items-center" style="min-height:100vh;">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Nanosense Dashboard Login</h1>
                                    </div>
                                    <form class="user">
                                        <div class="form-group">
                                            <input v-model="username" type="username" class="form-control form-control-user"
                                                id="exampleInputUsername" aria-describedby="usernameHelp"
                                                placeholder="Enter Username...">
                                        </div>
                                        <div class="form-group">
                                            <input v-model="password" type="password" class="form-control form-control-user"
                                                id="exampleInputPassword" placeholder="Password">
                                        </div>
                                        <button @click.prevent="login" class="btn btn-primary btn-user btn-block">
                                            Login
                                        </button>
                                    </form>
                                </div>
                                <div style="text-align: center;">
                                    <p style="font-size:12px">Copyright 2021 Nanosense Instrument Indonesia. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'Home',
  props: ['url'],
  data: () => {
      return {
          username: "",
          password: ""
      }
  },
  methods: {
      login() {
          let obj = {
              username: this.username,
              password: this.password
          }
          axios.post(`${this.url}/user/signin`, obj)
            .then((response) => {
                localStorage.setItem('token_monitor', response.headers['x-auth-login-token'])
                this.$router.push("/dashboard")
            })
            .catch((error) => {
                Swal.fire('Wrong Password/Username !', '', 'error')
                console.log(error.message)
            })
      }
  }
}
</script>
