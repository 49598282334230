<template>
  <router-view
    :url="url" 
    :urldatalog="urldatalog"
    :urlelto="urlelto"
    :urlpathgenimg="urlpathgenimg"
    :urlauge="urlauge"
    :urlqcm="urlqcm"
  />
</template>

<script>
export default {
  data: () => {
    return {
      url: 'https://testingmonitornanosense-caqa6tsjeq-et.a.run.app',
      urldatalog: 'https://monitordatalog.genosec19.co.id',
      urlelto: 'https://eltomonitor.nanosense-id.com',
      urlpathgenimg: 'https://nanopathmonitor.nanosense-id.com',
      urlauge: 'https://augemonitor.nanosense-id.com',
      urlqcm: 'https://qcmmonitor.nanosense-id.com'
    }
  },
}
</script>